<template>
    <div>
        <Quote/>
        <GoBack next="To'lov qilmagan o'quvchilar"/>
        <div class="container-fluid">

            <form @submit.prevent="searchCourses"
                  id="Search"
                  class="row justify-content-center"
            >
                <div class="col-11 col-sm-10 col-md-6">
                    <label for="exampleInputId1" class="form-label">Modulni tanlang</label>
                    <b-form-select
                        v-model="search.course"
                        class="form-control mb-4 pl-4"
                        id="exampleInputId1"
                    >
                        <optgroup label="Aktiv modullar" class="text-primary">
                            <option
                                v-for="search of getTakeCourses"
                                :key="search.id"
                                :label="search.module.name + ' kursining ' + search.name + ' (' + search.startMonth.name + ')' + ' moduli'"
                                :value="search.id"
                                v-show="search.isActive === true"
                            ></option>
                        </optgroup>
                        <optgroup label="Arxiv modullar" class="text-danger">
                            <option
                                v-for="search of getTakeCourses"
                                :key="search.id"
                                :label="search.module.name + ' kursining ' + search.name + ' (' + search.startMonth.name + ')' + ' moduli'"
                                :value="search.id"
                                v-show="search.isActive === false"
                            ></option>
                        </optgroup>
                    </b-form-select>
                </div>
                <button
                    type="submit"
                    class="col-11 col-sm-10 col-md-2 btn btn-primary my-auto text-end text-center ml-md-2 ml-sm-0"
                >
                    Ko'rish
                </button>
            </form>
            <div v-if="getNotPaidCoursesStudents.length !== 0" class="d-flex justify-content-end me-5 mb-3">
                <vue-excel-xlsx
                    style="background: transparent; border: none"
                    :data="getNotPaidCoursesStudents"
                    :columns="columns"
                    :file-name="'Kurs o\'quvchilari'"
                    :file-type="'xlsx'"
                    :sheet-name="'To\'lov qilmagan o\'quvchilar'"
                >
                    <img
                        id="tooltip-target-1"
                        src="../assets/print-icon.png"
                        alt=""
                        style="cursor: pointer"
                    />
                </vue-excel-xlsx>
            </div>

            <div class="row justify-content-center d-md-block">
                <div class="col-12">
                    <table class="table table-hover border">
                        <thead class="col-12">
                        <tr>
                            <th class="d-none d-md-table-cell">#</th>
                            <th class="d-table-cell">ID</th>
                            <th class="d-table-cell">Ismi</th>
                            <th class="d-table-cell">Familyasi</th>
                            <th class="d-none d-md-table-cell d-lg-table-cell">Telefon raqami</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(course, index) in getNotPaidCoursesStudents"
                            :key="course.id"
                            class="tr"
                        >
                            <td class="td1 d-none d-md-table-cell">{{ index + 1 }}</td>
                            <td class="d-table-cell">{{ course.id }}</td>
                            <td class="td1 d-table-cell">
                                <router-link
                                    :to="'/edit-admin-user/' + course.id"
                                    style="text-decoration: none; vertical-align: top"
                                    id="linking"
                                >
                                    <span class="texts hover-decoration">{{ course.givenName }}</span>
                                </router-link>
                            </td>
                            <td class="td1 d-table-cell">{{ course.familyName }}</td>
                            <td class="td1 d-none d-md-table-cell">{{ course.phoneNumber }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import GoBack from "../components/GoBack";
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "NotPaidStudentsCoursePage",
    components: {
        Quote,
        GoBack,
    },
    data() {
        return {
            search: {
                course: 0,
            },
            studentInfo: '',
            courseId: null,
            studentId: null,
            show: true,
            columns: [
                {
                    label: "Ismi",
                    field: "givenName"
                },
                {
                    label: "Familiyasi",
                    field: "familyName"
                },
                {
                    label: "Telefon raqami",
                    field: "phoneNumber"
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['getCourseStudents', 'getTakeCourses', 'getNotPaidCoursesStudents'])
    },
    methods: {
        ...mapActions([
            'fetchNotPaidCourseStudents',
            'fetchTakeCourse'
        ]),
        searchCourses() {
            this.fetchNotPaidCourseStudents(this.search.course)
        },
    },
    mounted() {
        this.show = true
        this.fetchTakeCourse()
            .then(() => {
                if (this.$route.params.courseId) {
                    this.search.course = this.$route.params.courseId
                    this.fetchNotPaidCourseStudents({courseId: this.search.course})
                    this.searchCourses()
                }
                this.show = false
            })
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Open+Sans:wght@400;600&display=swap');

* {
    margin: 0;
    padding: 0;
}

img {
    width: 20px;
    height: 20px;
}

input {
    padding: 5px;
    border: 1px solid black;
}

input:focus {
    border: none;
    outline: 2px solid #b505b4;
}

input::placeholder {
    padding: 0.5rem;
    font-size: 12px;
}

#Search {
    margin-top: 111px;
    margin-bottom: 50px;
}

select {
    height: 38px;
    border: 1px solid #80007F;
    border-radius: 10px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg") !important;
    background-repeat: no-repeat;
    background-size: 25px !important;
}

select::-ms-expand {
    display: none !important;
}

button {
    height: 38px;
    border-radius: 10px;
    background: #80007F;
}

p {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #EAEAEA;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

table {
    max-width: 100%;
    min-width: 100%;
}

table th {
    height: 60px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    background-color: #E5E5E5;
    color: rgba(0, 0, 0, 0.5);
}

.td {
    text-align: start;
    padding-left: 3em;
}

tbody td {
    height: 60px;
    width: 100px;
}

table tbody tr td {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: center;
    padding-left: 0;
    width: 185px;
    height: 56px;
    top: 56px;
    background-color: #FFFFFF;
    color: #000000;
}

#tooltip-target-1 {
    width: 100%;
    height: 100%;
}

</style>
